import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = ['select'];

  selectTargetConnected(element) {
    $(element).on('change', () => {
      this.update();
    });
  }

  selectTargetDisconnected(element) {
    $(element).off('change');
  }

  update() {
    this.element.requestSubmit();
  }
}
