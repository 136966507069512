import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'nav'];

  toggle() {
    const expanded = this.buttonTarget.ariaExpanded === 'true';
    this.buttonTarget.ariaExpanded = !expanded;
    if (expanded) {
      this.navTarget.classList.remove('is-show');
      document.body.style.overflow = 'auto';
    } else {
      this.navTarget.classList.add('is-show');
      document.body.style.overflow = 'hidden';
    }
  }
}
