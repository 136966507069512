import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {statusUrl: String, completeUrl: String, failureUrl: String}

    connect() {
        const interval = 5000
        setInterval(() => {
            fetch(this.statusUrlValue)
                .then(response => response.json())
                .then(json => {
                    if (json.subscription_status === 'active') {
                        location.href = this.completeUrlValue
                    } else if (json.subscription_status !== null) {
                        location.href = this.failureUrlValue
                    }
                })
        }, interval)
    }
}
