import { Controller } from "@hotwired/stimulus";
import { Collapse } from "bootstrap";
import $ from 'jquery';
import Select2 from 'select2';

// Connects to data-controller="announcement-form"
export default class extends Controller {
  static targets = ['radioButton', 'collapse'];

  connect() {
    this.toggleCollapse();
  }

  toggleCollapse() {
    const selectionRadioButton = this.radioButtonTargets.filter(radioButton => radioButton.value === 'selection')[0];
    if(selectionRadioButton.checked) {
      this.collapseTarget.removeAttribute('hidden');
    } else {
      this.collapseTarget.setAttribute('hidden', '');
    }
  }
}
