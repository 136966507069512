import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ['select2'];

  clear() {
    const inputElements = this.element.getElementsByTagName("input");
    for(const element of inputElements) {
      if (element.type !== "submit" && element.type !== "hidden") {
        element.value = "";
      }
    }
    for(const select2 of this.select2Outlets) {
      select2.clear();
    }
  }
}
