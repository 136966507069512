import {Controller} from "@hotwired/stimulus"
import {loadStripe} from '@stripe/stripe-js/pure'

export default class extends Controller {
  static targets = ['element', 'submit', 'error', 'spinner', 'cardTokenInput']
  static values = {stripeKey: String}

  connect() {
    this._mountStripeElement()
  }

  disconnect() {
    this.cardElement?.destroy()
  }

  async submit(event) {
    if (this.cardTokenInputTarget.value.length === 0) {
      event.preventDefault()
      this.submitTarget.setAttribute('disabled', '')

      const {token, error} = await this.stripe.createToken(this.cardElement)
      this.submitTarget.removeAttribute('disabled')
      if (token) {
        this.cardTokenInputTarget.value = token.id
        this.submitTarget.click()
      } else {
        this._showError(error.message)
      }
    }
  }

  async _mountStripeElement() {
    this.stripe = await loadStripe(this.stripeKeyValue)
    this.elements = this.stripe.elements()
    this.cardElement = this.elements.create('card', {hidePostalCode: true})
    this.cardElement.mount(this.elementTarget)
    this.cardElement.on('ready', () => {
      this.spinnerTarget.classList.add('d-none')
      this.submitTarget.removeAttribute('disabled')
    })
  }

  _showError(message) {
    this.errorTarget.textContent = message
    this.errorTarget.classList.remove('d-none')
  }
}
