import { Controller } from "@hotwired/stimulus";
import $ from 'jquery';
import Select2 from 'select2';

export default class extends Controller {
  static targets = ['select', 'dropdownParent'];
  static values = { tags: { type: Boolean, default: false } };

  connect() {
    Select2(); // See: https://github.com/select2/select2/issues/6081
    let options = {
      tags: this.tagsValue,
      language: {
        noResults: () => '見つかりません'
      }
    };
    if (this.dropdownParent) {
      options.dropdownParent = this.dropdownParent; // See: https://select2.org/troubleshooting/common-problems
    }
    this.select2 = $(this.selectTarget).select2(options);
  }

  dropdownParentTargetConnected() {
    this.dropdownParent = this.dropdownParentTarget;
  }

  clear() {
    this.select2.val(null).trigger('change');
  }
}
